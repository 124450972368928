*,
html {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #000;
}

a {
  text-decoration: none;
}

@media screen and (min-width: 450px) {
  body {
    height: 100vh;
  }
}

video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100vh;
}